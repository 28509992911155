<template>
    <div>
        <main-layout>
            <div class="row layout-spacing">
                <div class="col-12">
                    <div class="statbox widget box box-shadow">
                        <div class="widget-header">
                            <div class="row">
                                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                    <h4>Subscription Plans</h4>
                                </div>           
                            </div>
                        </div>
                        <div class="widget-content widget-content-area">
                            <div class="container">                                    
                                <div id="pricingWrapper" class="row">
                                    <div class="col-md-6 col-lg-4" v-for="(p, index) in packages" :key="index">
                                        <div class="card stacked mt-5">
                                            <div class="card-header pt-0">
                                                <span class="card-price">{{ formatNumberToCurrency(p.subscription_amount) }}</span>
                                                <h3 class="card-title mt-3 mb-1">{{ p.name }}</h3>
                                                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> -->
                                            </div>
                                            <div class="card-body">
                                                <div v-html="p.features"></div>
                                                <!-- <ul class="list-group list-group-minimal mb-3">
                                                    <li class="list-group-item d-flex justify-content-between align-items-center">N950 per delivery within Lagos and Abuja
                                                    </li>
                                                    <li class="list-group-item d-flex justify-content-between align-items-center">105 Pickups and Deliveries
                                                    </li>
                                                    <li class="list-group-item d-flex justify-content-between align-items-center">60 Days validity period
                                                    </li>
                                                    <li class="list-group-item d-flex justify-content-between align-items-center">Dedicated account manager
                                                    </li>
                                                    <li class="list-group-item d-flex justify-content-between align-items-center">Multiple pickup locations
                                                    </li>
                                                </ul> -->
                                                <a href="javascript:void(0)" @click="openPackageSelectionModal(index)" class="btn btn-block btn-primary">Buy Now</a>
                                            </div>
                                        </div>
                                    </div>                              
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main-layout>
        <div class="modal fade" id="select-subscription-modal" tabindex="-1" role="dialog" aria-labelledby="filter-modal" aria-hidden="true" v-if="selectedPackage != null">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <h5 class="modal-header">{{ selectedPackage.name }} package</h5>
                    <div class="modal-body">
                        <form @submit.prevent="confirmSubscription">
                            <div class="form-group">
                                <label for="Location selection">Select pickup {{ selectedPackage.pickup_location_type == 'single' ? 'location' : 'locations' }}</label>
                            </div>
                            <div class="location-selection-area border p-3 m-1 rounded" v-for="(l, index) in pickupLocations" :key="index">
                                <div class="form-row">
                                    <!-- <div class="col-sm form-group">
                                        <label for="Pickup location">Select country</label>
                                        <select name="country" class="form-control" v-model="l.country_id" @change="getStates(l.country_id)">
                                            <option value="">Select country</option>
                                            <option :value="country.id" v-for="country in countries" :key="country.id">{{ country.name }}</option>
                                        </select>
                                    </div> -->
                                    <div class="col-sm form-group">
                                        <label for="state">Select state</label>
                                        <select name="state" class="form-control" v-model="l.state_id" @change="getCities(l.state_id, index)" required>
                                            <option value="">Select state</option>
                                            <option :value="state.id" v-for="state in states" :key="state.id">{{ state.name }}</option>
                                        </select>
                                    </div>
                                    <div class="col-sm form-group">
                                        <label for="City">Select city</label>
                                        <select name="city" class="form-control" v-model="l.city_id" required>
                                            <option value="">Select city</option>
                                            <option :value="city.id" v-for="city in l.cities" :key="city.id">{{ city.name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col form-group">
                                        <label for="Address">Enter address</label>
                                        <input type="text" name="address" class="form-control" v-model="l.address" placeholder="Street address" required>
                                    </div>
                                </div>
                                <div  class="mb-5" v-if="selectedPackage.pickup_location_type == 'multiple'">
                                    <div class="float-right">
                                        <button class="btn btn-primary btn-sm" @click="addNewLocation" type="button"><i class="fa fa-plus"></i> Add new</button>
                                        <button class="btn btn-danger btn-sm" type="button" @click="removeLocation(index)" v-if="pickupLocations.length > 1"><i class="fa fa-minus"></i> Remove</button>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="text-center">
                                <button class="btn btn-success" type="submit">Subscribe</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useToast } from 'vue-toastification'
import MainLayout from '../../layouts/MainLayout.vue'
import instance from '../../presenter/api/network'
export default {
    components: {MainLayout},
    data(){
        return {
            packages: [],
            selectedPackage: null,
            countries: [],
            country: null,
            locationSelectionTemplate: {
                country_id: "",
                state_id: "",
                city_id: "",
                address: "",
                cities: []
            },
            pickupLocations: []
        }
    },
    methods: {
        getPackages(){
            instance.get("subscriptionList").then(res => {
                let responseData = res.data.data;
                this.packages = responseData;
            })
        },
        openPackageSelectionModal(index){
            this.selectedPackage = this.packages[index];
            $("#select-subscription-modal").modal("show");
        },
        getCountries(){
            instance.get("getCountryData?for=pickup").then(res => {
                let countries = res.data.data
                this.countries = countries;
                let found = this.countries.find(item => {
                    return item.name.toLowerCase() == 'nigeria';
                });
                
                if (found != -1){
                    this.country = found;
                    this.locationSelectionTemplate.country_id = found.id;
                    this.getStates(found.id);
                    this.addNewLocation();
                }
            })
        },
        getStates(country_id){
            instance.get("getStateData?for=pickup&country_id="+country_id).then(res => {
                this.states = res.data.data;
            })
        },
        getCities(state_id, locationIndex){
            // this.$root.startRequestLoader();
            instance.get("getCityData?for=pickup&state_id="+state_id).then(res => {
                this.pickupLocations[locationIndex].cities = res.data.data;
            }).finally(() => {
                // this.$root.endRequestLoader();
            })
        },
        addNewLocation(){
            let template = {...this.locationSelectionTemplate};
            this.pickupLocations.push(template);
        },
        removeLocation(index){
            this.pickupLocations.splice(index, 1);
        },
        confirmSubscription(){
            let data = {subscription_id: this.selectedPackage.id};
            data.location = [];
            this.pickupLocations.forEach(location => {
                data.location.push({
                    city_id: location.city_id,
                    state_id: location.state_id,
                    country_id: location.country_id,
                    address: location.address
                });
            });
            
            this.$root.startRequestLoader();
            $("#select-subscription-modal").modal("hide");
            
            instance.post("customer/subscribe-user", data).then(res => {
                useToast().success("Your subscription is successful. \nThis page will automatically refresh in a bit");
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }).catch(err => {
                if (err.response){
                    if (err.response.data.message == "user address error"){
                        useToast().error("Oops! The address you entered is not available for pickup");
                    }else if(err.response.data.message == "insufficient"){
                        useToast().error(err.response.data.data);
                    }
                    else if(err.response.data.message == "valid"){
                        useToast().error("You still have an active subscription on this plan.\nYou will be able to renew this plan when it expires\nOr you can choose a different plan.");
                    }
                    else{
                        useToast().error(err.response.data?.message ?? "There was an error subscribing you, please try again")
                    }
                    
                }else{
                    useToast().error("An unknown error occured");
                }
            }).finally(() => {
                this.$root.endRequestLoader();
            })
        }
    },
    mounted(){
        this.getPackages();
        this.getCountries();
        
    }
}
</script>
<style scoped>
@import url("../../assets/lib/plugins/pricing-table/css/component.css");
#pricingWrapper .stacked .card-header .card-price{
    width: unset;
}
</style>